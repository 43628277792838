import * as React from "react"
import { graphql, navigate } from "gatsby"
import { Global } from "@emotion/core"
import qs from "qs"

import Header from "../../components/header"
import Layout from "../../layouts"
import PageMetadata from "../../components/page-metadata"
import scrollToAnchor from "../../utils/scroll-to-anchor"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import FeaturedSites from "./featured-sites"
import FilteredShowcase from "./filtered-showcase"

const ShowcasePage = ({ data, location }) => {
  const showcase = React.createRef()
  const [filters, setFilters] = React.useState([])

  const updateFilters = filters => {
    setFilters([].concat(filters))
    scrollToAnchor(showcase.current)()
  }

  React.useEffect(() => {
    const { search = ``, pathname } = location
    const queryString = qs.stringify({ filters })

    if (search && search.replace(/^\?/, ``) !== queryString) {
      navigate(`${pathname}?${queryString}`, { replace: true })
      return
    }

    const { filters: incomingFilters } = qs.parse(search.replace(`?`, ``))
    if (incomingFilters && incomingFilters.length) {
      updateFilters(filters)
    }
  }, [location, filters])

  return (
    <Layout>
      <Global
        styles={{
          body: {
            overflowY: `visible`,
          },
        }}
      />
      <Header applyTopPadding={false} isFullWidth={true} />
      <main>
        <SkipNavTarget />
        <PageMetadata
          title="Showcase of Gatsby Websites"
          description="Gallery of sites using Gatsby across the web, find inspiration or inspect the code of popular projects."
        />
        <FeaturedSites
          setFilters={updateFilters}
          featured={data.featured.nodes}
        />
        <div id="showcase" css={{ height: 0 }} ref={showcase} />
        <FilteredShowcase
          filters={filters}
          setFilters={updateFilters}
          data={data}
        />
      </main>
    </Layout>
  )
}

export default ShowcasePage

export const pageQuery = graphql`
  query ShowcasePageQuery {
    featured: allWpShowcaseSite(
      filter: {
        showcaseSiteFields: { screenshot: { id: { ne: null } } }
        categories: { nodes: { elemMatch: { name: { in: "Featured" } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        slug
        categories {
          nodes {
            name
          }
        }
        showcaseSiteFields {
          title
          sourceUrl
          screenshot {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 350)
              }
            }
          }
          mainUrl
          description
          builtByUrl
          builtBy
        }
      }
    }
    allWpShowcaseSite(
      filter: { showcaseSiteFields: { screenshot: { id: { ne: null } } } }
    ) {
      nodes {
        id
        slug
        categories {
          nodes {
            name
            id
          }
        }
        showcaseSiteFields {
          title
          sourceUrl
          screenshot {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 350)
              }
            }
          }
          mainUrl
          description
          builtByUrl
          builtBy
        }
      }
    }
  }
`
